import { render, staticRenderFns } from "./AffiliateProgram.vue?vue&type=template&id=19bb3c2a"
import script from "./AffiliateProgram.ts?vue&type=script&lang=ts&external"
export * from "./AffiliateProgram.ts?vue&type=script&lang=ts&external"
import style0 from "./AffiliateProgram.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports