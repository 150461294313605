import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

import { SET_META } from 'booksprout-app'
import WebsiteButton from '../../../components/WebsiteButton.vue'

@Component({
  components: { WebsiteButton }
})
export default class AffiliateProgram extends BaseMixin {
  rewards: { title: string; amount: number }[] = []

  scrollToHowItWorks () {
    const howItWorks = this.$refs.howItWorks as HTMLElement
    if (howItWorks) {
      howItWorks.scrollIntoView({ behavior: 'smooth' })
    }
  }

  created () {
    void this.$store.dispatch(SET_META, {
      title: 'Affiliate Program',
      canonicalUrl: window.location.origin + this.websitePaths.affiliateProgram
    })
  }

  mounted () {
    this.rewards.push(
      { title: 'Growing Author', amount: 9 },
      { title: 'Pro Author', amount: 19 },
      { title: 'Bestselling Author', amount: 29 },
      { title: 'Publisher and ARC Services', amount: 59 }
    )
  }
}
